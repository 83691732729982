<template>
  <div id="app">
    <Header />
    <el-main>
      <router-view></router-view>
    </el-main>
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/Header";
import Footer from "@/components/Footer";
import config from "./config";

export default {
  name: "app",
  components: { Header, Footer },
  metaInfo() {
    return {
      titleTemplate:
        "%s EdiFin: Факторинговый маркетплейс для поиска выгодных условий финансирования вашего бизнеса.",
      meta: [
        {
          property: "og:type",
          content: "website",
        },
        {
          property: "og:image:url",
          content: config.og.image.url,
        },
        {
          property: "og:image:type",
          content: config.og.image.type,
        },
        {
          property: "og:image:width",
          content: config.og.image.width,
        },
        {
          property: "og:image:height",
          content: config.og.image.height,
        },
        {
          name: "theme-color",
          content: "#1A8A95",
        },
      ],
      link: [
        {
          rel: "canonical",
          href: this.getURI(),
        },
        {
          rel: "icon",
          href: "/favicon.ico",
          sizes: "any",
        },
        {
          rel: "icon",
          href: "/favicon.svg",
          type: "image/svg+xml",
        },
        {
          rel: "apple-touch-icon",
          href: "/apple-touch-icon.png",
        },
        {
          rel: "manifest",
          href: "/manifest.webmanifest",
        },
      ],
    };
  },
  methods: {
    getURI() {
      return `${config.baseURL}${this.$route.path}`;
    },
  },
};
</script>

<style lang="scss">
@import "~normalize.css";
@import "@/assets/styles/main.scss";

#app {
  font-family: "Inter", sans-serif;
  text-align: left;
  color: $gray-900;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  overflow-x: hidden;
  box-sizing: border-box;
}

.el-header,
.el-footer {
  padding: 0 !important;
}

.el-main {
  padding: 0 !important;
  overflow: hidden !important;
}

.container {
  margin: 0 auto;
  padding: 0 $space-24;
  max-width: 1366px;

  @include mobile {
    padding: 0 $space-16;
  }
}

.d-hide {
  display: none !important;
  @include mobile {
    display: block !important;
  }
}

.m-hide {
  @include mobile {
    display: none !important;
  }
}

.t24 {
  margin-top: $space-24;
}

.b24 {
  margin-bottom: $space-24;
}

.t120 {
  margin-top: $space-120;
}

.b120 {
  margin-bottom: $space-120;
}

.t80 {
  margin-top: $space-80;
}

.b80 {
  margin-bottom: $space-80;
}

.t64 {
  margin-top: $space-64;
}

.t56 {
  margin-top: $space-56;
}

.b56 {
  margin-bottom: $space-56;
}

.w-100 {
  width: 100%;
}

.light {
  font-weight: 300;
}

.reg {
  font-weight: 400;
}

.bold {
  font-weight: 700;
}

.title {
  position: relative;
  display: inline-block;

  &::after {
    content: "";
    position: absolute;
    bottom: -40px;
    left: 0;
    width: 240px;
    height: 8px;
    background-color: $green-500;

    @include mobile {
      width: 120px;
      bottom: -30px;
    }
  }

  &--center {
    &::after {
      left: 50%;
      transform: translateX(-50%);

      @include mobile {
        transform: translateX(0%);
        left: 0;
      }
    }
  }
}

.list {
  list-style: none;
  padding: 0;
  margin: 0;

  li {
    position: relative;
    margin-bottom: $space-16;
    padding-left: $space-32;
    font-size: 16px;
    font-weight: 300;
    line-height: 1.5;

    &::before {
      content: "";
      position: absolute;
      top: 11px;
      left: 0;
      width: 16px;
      height: 2px;
      background-color: $gray-900;
    }
  }
}

img {
  max-width: 100%;
  height: auto;
}

h1,
.h1 {
  margin: $space-56 0;
  font-size: 60px;
  font-weight: 700;
  line-height: 1.3;

  @include mobile {
    font-size: 32px;
  }
}

h2,
.h2 {
  margin: $space-40 0;
  font-size: 40px;
  font-weight: 700;
  line-height: 1.2;

  @include mobile {
    font-size: 24px;
  }
}

h3,
.h3 {
  margin: $space-32 0;
  font-size: 32px;
  font-weight: 700;
  line-height: 1.25;
}

h4,
.h4 {
  margin: $space-24 0;
  font-size: 24px;
  font-weight: 700;
  line-height: 1.3;

  @include mobile {
    font-size: 18px;
  }
}

h5,
.h5 {
  margin: $space-16 0;
  font-size: 18px;
  font-weight: 700;
  line-height: 1.3;
}

h6,
.h6 {
  margin: $space-16 0;
  font-size: 16px;
  font-weight: 700;
  line-height: 1.5;
}

.h1:first-child,
h1:first-child,
.h2:first-child,
h2:first-child,
.h3:first-child,
h3:first-child,
.h4:first-child,
h4:first-child,
.h5:first-child,
h5:first-child,
.h6:first-child,
h6:first-child {
  margin-top: 0;
}

.h1:last-child,
h1:last-child,
.h2:last-child,
h2:last-child,
.h3:last-child,
h3:last-child,
.h4:last-child,
h4:last-child,
.h5:last-child,
h5:last-child,
.h6:last-child,
h6:last-child {
  margin-bottom: 0;
}

p {
  font-weight: 300;
  line-height: 1.5;

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.p-1 {
  margin: $space-40 0 0;
  font-size: 40px;
}

.p-2 {
  margin: $space-32 0 0;
  font-size: 32px;
}

.p-3 {
  margin: $space-24 0 0;
  font-size: 24px;

  @include mobile {
    font-size: 16px;
  }
}

.p-4 {
  margin: $space-16 0 0;
  font-size: 18px;

  @include mobile {
    font-size: 16px;
  }
}

.p-5 {
  margin: $space-16 0 0;
  font-size: 16px;
}

.p-6 {
  margin: $space-16 0 0;
  font-size: 14px;
}

// links
a {
  text-decoration: none;
  color: $green-700;
  border-bottom: 1px solid transparent;
  transition: color $trans-anim, border-bottom-color $trans-anim;

  &:visited {
    color: $green-900;
  }

  &:focus {
    color: $green-900;
  }

  &:hover {
    color: $green-700;
    border-bottom-color: currentColor;
  }

  &.int-link {
    font-weight: inherit;
    color: currentColor;
    border-bottom-color: currentColor;
    transition: opacity $trans-anim;

    &:hover {
      opacity: 0.6;
    }
  }

  &.nav-link {
    color: $gray-900;
    text-decoration: none;
    border-bottom: none;

    &:active {
      color: $gray-900;
      font-weight: 700;
    }

    &:visited {
      color: $gray-900;
    }

    &:hover {
      color: $green-700;
    }
  }

  &.menu-link {
    color: $gray-900;
    text-decoration: none;
    border-bottom: none;

    &:visited {
      color: $gray-900;
    }

    &:hover,
    &:active {
      color: $green-700;
    }
  }

  &.disabled {
    color: $gray-500;
  }
}

button.btn-link,
a.btn-link {
  padding: 0;
  background: transparent;
  font-weight: 400;
  text-decoration: none;
  color: $green-700;
  border: none;
  border-bottom: 1px solid currentColor;
  transition: color $trans-anim, border-bottom-color $trans-anim;
  cursor: pointer;

  &:visited {
    color: $green-700;
  }

  &:focus {
    color: $green-900;
  }

  &:hover {
    color: $green-900;
    border-bottom-color: currentColor;
  }
}

// button
button.el-button,
a.el-button {
  line-height: 1.3;
  border-width: 2px !important;
  transition: $trans-anim;

  @include mobile {
    width: 100%;
  }

  &--primary {
    &:active,
    &:focus {
      border-color: $orange-800;
      background-color: $orange-800;
    }

    &:hover {
      border-color: $orange-700;
      background-color: $orange-700;
    }

    &.is-plain {
      color: $orange-600;
      border-color: inherit;
      background-color: $white;

      &:active,
      &:focus {
        border-color: $orange-700;
        background-color: $orange-700;
      }

      &:hover {
        border-color: $orange-600;
        background-color: $orange-600;
      }
    }
  }

  &--success {
    &:hover {
      border-color: $green-900;
      background-color: $green-900;
    }

    &.is-plain {
      border-color: inherit;
      background-color: $white;

      &:active,
      &:focus {
        border-color: $green-800;
        background-color: $green-800;
      }

      &:hover {
        border-color: $green-700;
        background-color: $green-700;
      }
    }
  }

  &--medium {
    line-height: 1.2;
  }
}

.el-button.is-disabled,
.el-button.is-disabled:hover,
.el-button.is-disabled:focus,
.el-button.is-disabled:active {
  background-color: $gray-100 !important;
  border-color: $gray-100 !important;
  color: $white !important;

  &.is-plain {
    background-color: transparent !important;
    border-color: $gray-100 !important;
    color: $gray-100 !important;
  }
}

// checkbox
.el-checkbox:hover
  .el-checkbox__input:not(.is-checked, .is-disabled)
  .el-checkbox__inner {
  border-color: $gray-300;
}

.el-checkbox:hover
  .el-checkbox__input.is-checked:not(.is-disabled)
  .el-checkbox__inner {
  border-color: $green-600;
  background-color: $green-600;
}

.el-checkbox__input.is-focus .el-checkbox__inner {
  border-color: $green-500 !important;
}

.el-checkbox__inner::after {
  border-width: 2px !important;
  height: 11px !important;
  left: 7px !important;
  top: 3px !important;
  width: 5px !important;
}

// radio

.el-radio__label {
  display: inline-block;
  white-space: initial;
  line-height: 1.5;
}

.el-radio-group {
  .el-radio {
    display: flex;
    align-items: flex-start;
    font-weight: 400;
    margin-bottom: $space-8;
  }
}

.el-radio:hover
  .el-radio__input:not(.is-checked, .is-disabled)
  .el-radio__inner {
  border-color: $gray-300;
}

.el-radio:hover .el-radio__input.is-checked:not(.is-disabled) .el-radio__inner {
  border-color: $green-600;
  background-color: $green-600;
}

.el-radio__inner::after {
  width: 8px !important;
  height: 8px !important;
}

// textarea
.el-textarea__inner {
  padding: 12px !important;
}

// select

.el-select {
  .el-input {
    position: relative;
    z-index: 10;
  }
}

.el-select-dropdown__wrap {
  max-height: 200px !important;
}

.el-select-dropdown.el-popper {
  margin-top: -5px !important;
  padding-top: 8px !important;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  z-index: 1 !important;
  box-shadow: none;

  .popper__arrow {
    display: none !important;
  }
}

.el-select-dropdown__item {
  font-family: "Inter", sans-serif;
  font-weight: 300 !important;
}

.el-select-dropdown__item.hover,
.el-select-dropdown__item:hover {
  background-color: $gray-light !important;
}

.el-select-dropdown__item.selected {
  color: $gray-900 !important;
  font-weight: 400 !important;
}

// dialog
@include mobile {
  .el-popup-parent--hidden {
    padding-right: 0px !important;
  }
}
.el-dialog__wrapper {
  .el-dialog__header {
    padding: $space-56 $space-56 0;

    @include mobile {
      padding: $space-64 $space-24 0;
    }
  }

  .el-dialog__title {
    font-size: 40px;
    font-weight: 700;
    line-height: 1.2;

    @include mobile {
      font-size: 24px;
    }
  }

  .el-dialog__body {
    padding: 0 $space-56 $space-40;

    @include mobile {
      padding: 0 $space-24 $space-24 $space-24;
    }

    p {
      margin: $space-24 0;
      font-size: 16px;

      &.policy {
        margin: $space-24 0 0;
        font-size: 14px;
      }
    }
  }

  .el-dialog {
    max-width: 640px;
    width: 100%;
    border-radius: 16px;

    @include mobile {
      max-width: 90vw;
    }
  }

  .el-dialog__headerbtn .el-dialog__close {
    font-size: 24px;
    color: $gray-500;
    transition: $trans-anim;
  }

  .el-dialog__headerbtn {
    top: 26px;
    right: 26px;
  }

  .el-dialog__headerbtn:focus .el-dialog__close,
  .el-dialog__headerbtn:hover .el-dialog__close {
    color: $gray-900;
  }
}

.hero {
  position: relative;
  padding: $space-120 0 $space-120;

  @include mobile {
    padding: $space-56 0 $space-56;
  }

  &__graph {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: -1;
    height: 100%;
    width: 100%;

    @include mobile {
      display: none;
    }

    .graph {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100% !important;
      height: 100% !important;
    }

    &--support {
      transform: translateY(-30%);
    }
  }

  &__container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include tablet {
      flex-wrap: wrap;
      flex-direction: column;
      align-items: flex-start;
    }
  }

  &__title {
    @include mobile {
      margin-bottom: 34px;
    }

    &--main {
      margin-bottom: $space-32;

      @include mobile {
        margin-bottom: $space-24;
      }
    }
  }

  &__btn {
    @include mobile {
      button {
        padding: 12px 0;
        font-size: 18px;
      }
    }
  }

  &__text {
    margin-top: $space-16;
    max-width: 870px;
    p {
      color: $gray-700;
    }

    &--main {
      margin-bottom: $space-56;
      max-width: 650px;

      @include mobile {
        margin-bottom: $space-32;
        max-width: none;
      }

      p {
        color: $gray-500;
      }
    }
  }

  &__descr {
    position: relative;
    z-index: 20;
  }

  &__img {
    position: absolute;
    top: 50%;
    transform: translate(5%, -46%);
    right: 0;
    z-index: 10;

    @include tablet {
      position: relative;
      transform: translate(0%, 12%);
    }
  }

  &--center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @include mobile {
      align-items: flex-start;
    }

    .hero__text {
      text-align: center;

      @include mobile {
        text-align: left;
      }
    }
  }

  &--no-bg {
    background: none;
  }
}

// VueCarousel
.VueCarousel-inner {
  padding: $space-16 0 $space-48;
}

.VueCarousel-slide > div {
  padding-right: 15px;
  padding-left: 15px;

  &:hover {
    cursor: pointer;
  }
}

.VueCarousel-navigation-button {
  @include tablet {
    padding: 0 !important;
  }

  &:focus {
    outline: none !important;
  }
}

// tech page

.tech {
  &-row {
    margin: $space-80 0 $space-120;
    flex-wrap: wrap;
  }

  &-img {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    @include mobile {
      max-width: 300px;
      margin: 0 auto;
    }
  }

  @include mobile {
    h1,
    h2 {
      margin-bottom: $space-24;
    }
  }

  .list {
    margin-top: $space-16;
  }
}

.lock {
  overflow: hidden;
}

// vue-typer
.vue-typer {
  display: block;

  .custom.char {
    color: $green-600;
  }

  .custom.caret {
    margin-left: 18px;
    background-color: $green-600;
    width: 4px;

    @include mobile {
      margin-left: 5px;
      width: 2px;
    }
  }
}

// animation
.animated {
  animation-duration: 1s;
  animation-fill-mode: both;

  &.infinite {
    animation-iteration-count: infinite;
  }

  &.hinge {
    animation-duration: 2s;
  }
}

.backIn {
  animation-name: backIn;
}

@keyframes backIn {
  0% {
    transform: translateY(50%);
    opacity: 0;
  }

  to {
    transform: translateY(0%);
    opacity: 1;
  }
}

@mixin delayList {
  $start: 0;
  $end: 10;
  @for $i from $start through $end {
    $iletter: $i * $end/2;
    $prefix: if($iletter < 10, "0", "");
    .delay-#{$prefix}#{$iletter} {
      animation-delay: #{$i/4}s;
    }
  }
}

.VueCarousel-navigation-button svg path {
  transition: stroke ease-in 0.25s;
}

.VueCarousel-navigation-button:hover svg path {
  stroke: #232d27 !important;
}

.el-dialog__body {
  word-break: normal;
}

@include delayList;
</style>
