import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    test: "test",
    component: () => import(/* webpackChunkName: "Home" */ "@/views/Home"),
  },
  {
    path: "/about",
    name: "About",
    component: () => import(/* webpackChunkName: "About" */ "@/views/About"),
  },
  {
    path: "/marketplace",
    name: "Marketplace",
    component: () =>
      import(/* webpackChunkName: "Marketplace" */ "@/views/Marketplace"),
  },
  {
    path: "/support",
    name: "Support",
    component: () =>
      import(/* webpackChunkName: "Support" */ "@/views/Support"),
  },
  {
    path: "/error",
    name: "Error",
    component: () => import(/* webpackChunkName: "Error" */ "@/views/Error"),
  },
  {
    path: "/success",
    name: "Success",
    component: () =>
      import(/* webpackChunkName: "Success" */ "@/views/Success"),
  },
  {
    path: "/404",
    name: "404",
    component: () =>
      import(/* webpackChunkName: "NotFound" */ "@/views/NotFound"),
  },
  {
    path: "/demo",
    name: "Demo",
    component: () => import(/* webpackChunkName: "Demo" */ "@/views/Demo"),
  },
  {
    path: "*",
    redirect: "/404",
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
