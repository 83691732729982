import Vue from "vue";
import App from "@/App.vue";
import router from "@/router";
import Meta from "vue-meta";
import Modal from "vue-js-modal";

import ScrollAnimate from "@/plugins/animate-onscroll";
import "@/assets/styles/element-variables.scss";

import {
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Menu,
  Submenu,
  MenuItem,
  MenuItemGroup,
  Input,
  Radio,
  RadioGroup,
  Checkbox,
  CheckboxButton,
  CheckboxGroup,
  Select,
  Option,
  OptionGroup,
  Button,
  Form,
  FormItem,
  Row,
  Col,
  Rate,
  Container,
  Header,
  Main,
  Footer,
  Slider,
} from "element-ui";

const components = [
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Menu,
  Submenu,
  MenuItem,
  MenuItemGroup,
  Input,
  Radio,
  RadioGroup,
  Checkbox,
  CheckboxButton,
  CheckboxGroup,
  Select,
  Option,
  OptionGroup,
  Button,
  Form,
  FormItem,
  Row,
  Col,
  Rate,
  Container,
  Header,
  Main,
  Footer,
  Slider,
];

components.forEach((component) => Vue.use(component));

Vue.use(Meta, {
  refreshOnceOnNavigation: true,
});

Vue.config.productionTip = false;

Vue.use(Modal, {
  resizable: true,
  scrollable: true,
});

Vue.filter("numСat", (val) => {
  return new Intl.NumberFormat("ru-RU").format(val);
});

Vue.directive("animate-onscroll", {
  inserted(el, binding) {
    const scrollAnimate = ScrollAnimate(Date.now());
    const previousClassName = el.className;
    let lastScrollTop = window.pageYOffset;

    function animStart() {
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      const isUpwards = scrollTop < lastScrollTop;
      scrollAnimate.run(el, binding, { isUpwards, previousClassName });
      lastScrollTop = scrollTop <= 0 ? 0 : scrollTop;
    }

    animStart();

    window.addEventListener("scroll", animStart, false);
  },
});

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
