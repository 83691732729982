<template>
  <el-footer height="auto">
    <div class="container">
      <Copyright />
      <div class="footer-row">
        <div class="footer-col footer-copy">&copy; EdiFin, 2023</div>
        <div class="footer-col footer-nav">
          <el-menu mode="horizontal">
            <el-menu-item index="1">
              <router-link :to="{ name: 'About' }" class="footer-link"
                >О сервисе</router-link
              >
            </el-menu-item>
            <el-menu-item index="2">
              <router-link :to="{ name: 'Marketplace' }" class="footer-link"
                >Маркетплейс</router-link
              >
            </el-menu-item>
            <el-menu-item index="3">
              <router-link :to="{ name: 'Support' }" class="footer-link"
                >Поддержка</router-link
              >
            </el-menu-item>
          </el-menu>
        </div>
        <div class="footer-col footer-policy">
          <p>
            <a href="https://ediweb.com/ru-ru/policies/privacy">
              Политика конфиденциальности
            </a>
          </p>
        </div>
        <div class="footer-col footer-social">
          <a
            href="https://vk.com/ediweb_russia"
            target="_blank"
            rel="noopener nofollow"
          >
            <svg
              width="28"
              height="20"
              viewBox="0 0 28 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M22.7 9.85C22.7 9.85 26.3 4.77 26.66 3.1C26.78 2.5 26.52 2.17 25.9 2.17C25.9 2.17 23.83 2.17 22.78 2.17C22.06 2.17 21.8 2.48 21.59 2.93C21.59 2.93 19.9 6.53 17.85 8.8C17.19 9.53 16.85 9.75 16.49 9.75C16.2 9.75 16.06 9.51 16.06 8.84V3.05C16.06 2.24 15.96 2 15.3 2H10.29C9.9 2 9.66 2.22 9.66 2.52C9.66 3.28 10.83 3.46 10.83 5.52V9.8C10.83 10.66 10.78 10.99 10.38 10.99C9.31 10.99 6.76 7.29 5.32 3.1C5.03 2.24 4.72 2 3.91 2H0.79C0.33 2 0 2.31 0 2.76C0 3.59 0.98 7.46 4.82 12.63C7.39 16.12 10.78 18 13.83 18C15.69 18 16.14 17.69 16.14 16.9V14.23C16.14 13.56 16.4 13.28 16.78 13.28C17.21 13.28 17.97 13.42 19.74 15.16C21.84 17.16 21.98 18 23.13 18H26.64C27 18 27.33 17.83 27.33 17.24C27.33 16.45 26.3 15.05 24.73 13.38C24.09 12.52 23.04 11.59 22.7 11.16C22.22 10.66 22.37 10.37 22.7 9.85Z"
                fill="white"
              />
            </svg>
          </a>
        </div>
      </div>
    </div>
  </el-footer>
</template>

<script>
export default {
  components: {
    Copyright: () => import("@/components/Copyright.vue"),
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/main.scss";

.el-footer {
  background: #14707b;
}

.el-menu.el-menu--horizontal {
  background-color: transparent;
  border-bottom: none;

  @include mobile {
    display: flex;
    flex-direction: column;
  }

  .el-menu-item {
    padding: 0;
    margin-right: 40px;
    height: auto;
    line-height: normal;
    border-bottom: none;

    &.is-active {
      border: none;
    }

    &:last-child {
      margin-right: 0;
    }

    &:not(.is-disabled):hover,
    &:not(.is-disabled):focus {
      background-color: transparent;
    }

    @include mobile {
      margin-bottom: 24px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    a {
      color: $white;
      text-decoration: none;
      border-bottom: none;

      &:visited {
        color: $white;
      }

      &:focus {
        color: $teal-200;
      }

      &:hover {
        color: $teal-200;
      }
    }
  }
}

.footer {
  &-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    padding: 32px 0;

    @include mobile {
      padding: 40px 0;
      flex-direction: column-reverse;
      align-items: flex-start;
      justify-content: flex-start;
    }
  }

  &-col {
    margin-right: 10px;

    &:last-child {
      margin-right: 0;
    }

    @include mobile {
      &:not(:first-child) {
        margin-bottom: 40px;
      }
    }
  }

  &-policy {
    text-align: right;

    @include mobile {
      text-align: left;
    }

    a {
      color: $white;
      text-decoration: none;
      border-bottom: none;

      &:visited {
        color: $white;
      }

      &:focus {
        color: $teal-200;
      }

      &:hover {
        color: $teal-200;
      }
    }
  }

  &-copy {
    font-size: 16px;
    line-height: 1.5;
    color: #d0f3ef;
  }

  &-social {
    display: flex;
    justify-content: flex-end;

    a {
      margin-right: 24px;
      display: block;
      border-bottom: none;

      svg {
        path {
          fill: $white;
          transition: fill $trans-anim;
        }
      }

      &:last-child {
        margin-right: 0;
      }

      &:hover {
        border-bottom: none;

        svg {
          path {
            fill: $teal-200;
          }
        }
      }
    }
  }
}
</style>
