module.exports = {
  baseURL: "https://edifin.ru",
  og: {
    type: "website",
    image: {
      url: "https://edifin.ru/og.png",
      width: "512",
      height: "512",
      type: "image/png",
    },
  },
  pages: {
    // eslint-disable-next-line
    content: [
      "/",
      "/about",
      "/marketplace",
      "/support",
    ],
    // eslint-disable-next-line
    tech: [
      "/success",
      "/error",
      "/404",
    ],
  },
};
