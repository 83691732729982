<template>
  <el-header
    height="auto"
    class="header"
    :class="{ '': !isMenuOpen, isOpen: isMenuOpen }"
  >
    <div class="container">
      <div class="header-row">
        <div class="header-logo">
          <router-link :to="{ name: 'Home' }" class="menu-link">
            <img v-if="!isMenuOpen" src="@/assets/img/logo.svg" alt="" />
            <img v-else src="@/assets/img/logo-white.svg" alt="" />
          </router-link>
        </div>

        <div class="header-menu">
          <div class="header-burger mobile-nav" @click="toggleMenu">
            <img v-if="!isMenuOpen" src="@/assets/img/burger.svg" alt="" />
            <img v-else src="@/assets/img/close.svg" alt="" />
          </div>
          <el-menu
            mode="horizontal"
            :class="{ '': !isMenuOpen, isOpen: isMenuOpen }"
          >
            <el-menu-item>
              <router-link :to="{ name: 'About' }">О сервисе</router-link>
            </el-menu-item>
            <el-menu-item>
              <router-link :to="{ name: 'Marketplace' }"
                >Маркетплейс</router-link
              >
            </el-menu-item>
            <el-menu-item>
              <router-link :to="{ name: 'Support' }">Поддержка</router-link>
            </el-menu-item>
            <div class="mobile-actions">
              <el-button class="mobile-btn" type="primary" @click="onClick">
                Связаться
              </el-button>
              <div class="mobile-socials">
                <a href="#">
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11.74 20V10.88H14.8L15.26 7.32H11.74V5.05C11.74 4.02 12.03 3.32 13.5 3.32H15.38V0.14C15.06 0.1 13.94 0 12.64 0C9.93 0 8.07 1.66 8.07 4.7V7.32H5V10.88H8.07V20H11.74Z"
                      fill="white"
                    />
                  </svg>
                </a>
                <a href="#">
                  <svg
                    width="28"
                    height="20"
                    viewBox="0 0 28 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M22.7 9.85C22.7 9.85 26.3 4.77 26.66 3.1C26.78 2.5 26.52 2.17 25.9 2.17C25.9 2.17 23.83 2.17 22.78 2.17C22.06 2.17 21.8 2.48 21.59 2.93C21.59 2.93 19.9 6.53 17.85 8.8C17.19 9.53 16.85 9.75 16.49 9.75C16.2 9.75 16.06 9.51 16.06 8.84V3.05C16.06 2.24 15.96 2 15.3 2H10.29C9.9 2 9.66 2.22 9.66 2.52C9.66 3.28 10.83 3.46 10.83 5.52V9.8C10.83 10.66 10.78 10.99 10.38 10.99C9.31 10.99 6.76 7.29 5.32 3.1C5.03 2.24 4.72 2 3.91 2H0.79C0.33 2 0 2.31 0 2.76C0 3.59 0.98 7.46 4.82 12.63C7.39 16.12 10.78 18 13.83 18C15.69 18 16.14 17.69 16.14 16.9V14.23C16.14 13.56 16.4 13.28 16.78 13.28C17.21 13.28 17.97 13.42 19.74 15.16C21.84 17.16 21.98 18 23.13 18H26.64C27 18 27.33 17.83 27.33 17.24C27.33 16.45 26.3 15.05 24.73 13.38C24.09 12.52 23.04 11.59 22.7 11.16C22.22 10.66 22.37 10.37 22.7 9.85Z"
                      fill="white"
                    />
                  </svg>
                </a>
              </div>
            </div>
          </el-menu>
        </div>

        <div class="header-btn">
          <el-button type="success" size="small" @click="onClick">
            Связаться
          </el-button>
        </div>
      </div>
    </div>

    <v-dialog name="hcontact">
      <h2 class="b24">Отправить запрос</h2>
      <p class="b24">
        Мы свяжемся с вами, и найдем лучшие ставки и условия среди факторов,
        подключенных к EdiFin
      </p>
      <v-form :action="'https://ediweb.com/subscribe/9968c31f'" />
    </v-dialog>
  </el-header>
</template>

<script>
export default {
  name: "v-header",
  components: {
    "v-dialog": () => import("@/components/Dialog"),
    "v-form": () => import("@/components/Form"),
  },
  data() {
    return {
      isMenuOpen: false,
    };
  },
  methods: {
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen;
      document.querySelector("body").classList.toggle("lock");
    },
    closeMenuOnChangeRoute() {
      this.isMenuOpen = false;
      this.$modal.hide("contact");
      document.querySelector("body").classList.remove("lock");
    },
    onClick() {
      this.$modal.show("hcontact");
    },
  },
  watch: {
    $route() {
      window.scrollTo(0, 0);
      this.closeMenuOnChangeRoute();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/main.scss";

.el-header {
  position: relative;

  &::before {
    content: "";
    position: absolute;
    background-color: $teal-900;
    top: 0;
    left: -200%;
    width: 100%;
    height: 100%;
    transition: left 0.4s ease-in-out;
  }

  @include mobile {
    .container {
      padding: 0 24px;
    }
  }

  &.isOpen {
    &::before {
      left: 0;
    }
  }
}
.header {
  &-row {
    padding: $space-16 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &-logo {
    position: relative;
    display: flex;
    align-items: center;

    @include mobile {
      img {
        max-height: 24px;
      }
    }
  }

  &-burger {
    cursor: pointer;
  }

  &-menu {
    display: flex;
    justify-content: center;

    @include mobile {
      align-items: center;
    }
  }

  &-btn {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    @include mobile {
      display: none;
    }
  }
}

.el-menu--horizontal > .el-menu-item,
.el-menu.el-menu--horizontal {
  border-bottom: none !important;
}
.el-menu--horizontal.el-menu {
  border: none;

  .el-menu-item {
    background: transparent;
    a {
      color: $gray-900;
      display: block;
      font-size: 16px;
      border-bottom: none;

      &.router-link-exact-active {
        color: $green-700;
      }

      &:hover {
        color: $green-700;
      }
    }

    @include mobile {
      padding: 0 24px;
    }
  }
  .mobile {
    &-actions {
      display: none;
      padding: 0 $space-24;
    }

    &-btn {
      margin-top: $space-80;
    }

    &-socials {
      margin-top: $space-80;

      a {
        margin-right: 24px;
        border-bottom: none;
      }
    }
  }

  @include mobile {
    transition: left 0.4s ease-in-out;
    position: absolute;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: calc(100vh - 100%);
    top: 100%;
    left: -200%;
    background-color: $teal-900;
    z-index: 101;
    padding-top: 60px;

    .mobile-actions {
      display: block;
    }
  }
  &.isOpen {
    left: 0;

    li {
      a {
        color: $white;
      }

      &:hover {
        background-color: transparent;
      }
    }
  }
}

.mobile-nav {
  display: none;
  position: relative;

  @include mobile {
    display: block;
  }
}
</style>
